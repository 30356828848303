import Vue from 'vue'
import VueHead from 'vue-head'
import App from './App.vue'
import router from './router'
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";
import './styles/main.scss'

Vue.config.productionTip = false
Vue.use(LottieAnimation);
Vue.use(VueHead);

new Vue({
  render: h => h(App),
  router
}).$mount('#app')
