<template>
    <div class="checkout-survey">
        <div class="margin-top-16 margin-bottom-16">Let us know when your big day is so we can be sure to send you a little something special!</div>
        <div class="input-group hasData date">
            <label>Birthday</label>
            <input type="date" v-model="customer.birthday" class="full-width"/>
        </div>
        <div class="input-group hasData select">
            <label>How did you find out about Ora?</label>
            <select v-model="customer.referred_by" class="full-width">
                <option v-for="option in referralOptions" v-bind:key="option.value" v-bind:value="option.value" class="full-width">{{ option.name }}</option>
            </select>
        </div>
        <div v-for="option in referralOptions" v-bind:key="option.name">
            <div v-if="customer.referred_by === option.value && option.writeIn" v-bind:class="[ 'input-group' , { hasData: customer.referred_by_write_in }]">
                <label>{{ option.writeInQuestion }}</label>
                <input type="text" v-model="customer.referred_by_write_in" v-bind:placeholder="option.writeInQuestion" class="full-width"/>
            </div>
        </div>
        <div class="actions margin-0">
            <button v-bind:class="['button', 'button--solid', 'button--kia', surveyStatus, { 'disabled': !surveyHasData() } ]" v-on:click="submitReferralData()">
                <span v-if="surveyStatus === 'processing'" class="spinner"><lottie-animation path="./spinner.json" /></span>
                <span v-else-if="surveyStatus === 'complete'">Thanks!</span>
                <span v-else>Submit</span>
            </button>
        </div>
    </div>
</template>

<script>
    
    import { firebase } from "@firebase/app"
    import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";
    const axios = require('axios');

    export default {
        name: 'CheckoutSurvey',
        components: {
            LottieAnimation
        },
        props: {
            order: Object,
            checkout: Object
        },
        data: function () {
            return {
                customer: this.order.customer,
                surveyStatus: 'open', // open, processing, complete
                referralOptions: [
                    { "name": "Another website / blog", "value": "Another Website / Blog", "writeIn": true, "writeInQuestion": "Let us know here.." },
                    { "name": "Amazon", "value": "Amazon", "writeIn": false },
                    { "name": "At an event", "value": "Event", "writeIn": true, "writeInQuestion": "Which event?" },
                    { "name": "Facebook", "value": "Facebook", "writeIn": false },
                    { "name": "Friend / family", "value": "Friend / Family", "writeIn": false },
                    { "name": "Health practitioner", "value": "Health Practitioner", "writeIn": false },
                    { "name": "In-store", "value": "In-store", "writeIn": true, "writeInQuestion": "Which store?" },
                    { "name": "Instagram", "value": "Instagram", "writeIn": true, "writeInQuestion": "Where on Instagram?" },
                    { "name": "Magazine", "value": "Print (magazines etc.)", "writeIn": true, "writeInQuestion": "Which publication?" },
                    { "name": "YouTube", "value": "YouTube", "writeIn": true, "writeInQuestion": "Where on YouTube?" },
                    { "name": "TikTok", "value": "TikTok", "writeIn": true, "writeInQuestion": "Where on TikTok?" },
                    { "name": "Online search (Google/Bing/Yahoo)", "value": "Online Search", "writeIn": false },
                    { "name": "Pinterest", "value": "Pinterest", "writeIn": false },
                    { "name": "Podcast", "value": "Podcast", "writeIn": true, "writeInQuestion": "Which Podcast?" },
                    { "name": "TV", "value": "TV", "writeIn": true, "writeInQuestion": "Where on TV?" },
                    { "name": "Other", "value": "Other", "writeIn": true, "writeInQuestion": "Let us know here.." },
                ]
            }
        },
        created: function() {
            console.log("survey checkout",this.checkout)
            // If we already have data, mark the survey as complete
            if (this.customer.referred_by) { this.surveyStatus = 'complete'; }

        },
        methods: {
            submitReferralData: async function() {

                this.surveyStatus = 'processing';
                
                var referred_by = this.customer.referred_by_write_in ? `${this.customer.referred_by}: ${this.customer.referred_by_write_in}` : this.customer.referred_by;

                var referralData = {
                  "first_name"    : this.customer.first_name,
                  "last_name"     : this.customer.last_name,
                  "email"         : this.customer.email,
                  "birthday"      : this.customer.birthday,
                  "referrer"      : referred_by,
                  "products_added": this.order.line_items.map(x => x.title),
                  "product_ids"   : this.order.line_items.map(x => String(x.product_id)),
                  "variant_ids"   : this.order.line_items.map(x => String(x.variant_id)),
                  "value"         : Number(this.order.total_price),
                  "order_number"  : this.order.name,
                  "order_id"      : `https://oraorganic.myshopify.com/admin/orders/${this.order.id}`,
                  "order_date"    : (new Date(this.order.created_at)).toISOString()
                }

                // console.log("referralData:",JSON.stringify(referralData));

                // Save Referral Data to Firebase
                await firebase.saveReferralDataToCheckout(this.checkout.id, this.customer);

                // Send data to Airtable, Klaviyo
                await axios({
                    "method": "POST",
                    "url": "https://us-central1-survey-258015.cloudfunctions.net/postCheckoutSurvey",
                    "data": referralData,
                    "headers": {
                        "Content-Type": "application/json",
                        "Accept": "application/json"
                    }
                }).then((response) => {
                    console.log({ "Success": response.data });
                }).catch((error) => {
                    console.log({ "Error": error });
                })

                this.surveyStatus = 'complete';

            },
            surveyHasData: function () {
                // console.log("has data:",this.customer.referred_by && this.customer.birthday)
                return this.customer.referred_by && this.customer.birthday;
            }
        },
        watch: {
            'customer.referred_by': {
                handler: function() {
                    this.surveyStatus = 'open';
                    this.customer.referred_by_write_in = "";
                },
            }
        }
    };

</script>
