// Add the Firebase services that you want to use
import { firebase } from "@firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";

const firebaseConfig = {
  apiKey: "AIzaSyAEwJr0nqDp-1ErVdyegKGy3HsUvGMfvHM",
  authDomain: "ora-checkout.firebaseapp.com",
  projectId: "ora-checkout",
  storageBucket: "ora-checkout.appspot.com",
  messagingSenderId: "619877541125",
  appId: "1:619877541125:web:45d99cc31e64cf7043036c",
  measurementId: "G-0894JR10P3"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

// utils
const db = firebase.firestore();
const auth = firebase.auth();
const functions = firebase.functions();
// const storage = firebase.storage();

// collection refs
const usersCollection = db.collection('users');

// Getters
firebase.getCurrentUser = () => {
    return new Promise((resolve, reject) => {
        const unsubscribe = firebase.auth().onAuthStateChanged(user => {
            unsubscribe();
            resolve(user);
        }, reject);
    })
};

firebase.getUser = (key, value) => {
  var usersRef = db.collection("users");
  var query = usersRef.where(key, "==", value);
  var result = [];

  // console.log("Users DB:",usersRef);

  return query.get().then(function(querySnapshot) {
    if (querySnapshot.docs.length > 0) {
      querySnapshot.forEach(function(doc) {
          console.log(doc.id, " => ", doc.data());
          var user = doc.data();
          user.id = doc.id;
          result.push(user);
      });  
    } else {
      console.log("No document found where",key,"= "+value)
    }
    return result;
  }).catch(function(error) {
      console.log("Error getting documents: ", error);
  });
  
}

firebase.saveCheckout = async (data, checkout_id) => {

  var ref = db.collection('checkouts');

  if (checkout_id) {
    return await ref.doc(checkout_id).set(data).then(
    function(response) {
      // console.log("Saved Checkout:",response);
      return response;
    });
  } else {
    return await ref.add(data).then((docRef) => {
      // console.log("docRef:",docRef);
      return docRef.id;
    })
  }

  
}

firebase.getCheckout = async (key) => {

  var doc = await db.collection('checkouts').doc(key).get();

  if (!doc.exists) {
    // console.log('No such document!');
    return;
  } else {
    // console.log('Document data:', doc.data());
    return doc.data();
  }

}

firebase.getCheckoutId = async (key) => {

  var doc = await db.collection('checkouts').doc(key).get();

  if (!doc.exists) {
    // console.log('No such document!');
    return;
  } else {
    // console.log('Document data:', doc.id);
    return doc.id;
  }

}

firebase.findCheckout = async (order_id) => {

  // console.log("order_id:",order_id)

  var snapshot = await db.collection('checkouts').where('order.id','==',order_id).get();
  var result = [];

  if (snapshot.empty) {
    // console.log('No such document!');
    return;
  } else {
    snapshot.forEach(doc => {
      console.log(doc.id, '=>', doc.data());
      result.push(doc.data());
    });
    // return doc.data();
    return result;
  }


}

firebase.saveOrder = async (data, order_id) => {

  var ref = db.collection('orders');

  if (order_id) {
    return await ref.doc(order_id).set(data)
    .then(function(response) {
      // console.log("Saved order:",response);
      return response;
    }).catch(function(err) {
      // console.log("error saving order:",err);
      return err;
    });
  } else {
    return await ref.add(data).then((docRef) => {
      // console.log("docRef:",docRef);
      return docRef.id;
    })
  }

}


firebase.saveOrderToCheckout = async (key, data) => {
  var ref = db.collection('checkouts');

  return await ref.doc(key).update({ order: data },
  function(response) {
    // console.log("Saved Order:",response);
    return response;
  });
}

firebase.saveReferralDataToCheckout = async (key, data) => {
  var ref = db.collection('checkouts');

  return await ref.doc(key).update({ 'order.customer': data }).then(
  function(response) {
    // console.log("Saved Customer:",response);
    return response;
  });
}

// export utils/refs
export {
	db,
	auth,
	functions,
	usersCollection
}