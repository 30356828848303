<template>
    <div>
        <div v-if="cart">
            <Checkout :cart="cart"></Checkout>
        </div>
        <div v-else>
            <!-- <h1>Must provide a cart!</h1> -->
        </div>
    </div>
</template>

<script>

import { firebase } from "@firebase/app"

import Checkout from "../components/Checkout.vue";

export default {
    name: 'Home',
    params: {
        cart: Object
    },
    components: {
        Checkout
    },
    data: () => {
        return {
            cart: undefined
        }
    },
    created: async function () {

        
        // If we have a cart, then render the cart data from Firebase
        if (this.$route.params.id) {

            this.cart = await firebase.getCheckout(this.$route.params.id);
            

        }
        
    }
};

</script>