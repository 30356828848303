<template>
  <div id="app">
    <div class="checkout">

      <div class="header">
        <div class="logo">
          <img v-bind:src="logo" />
        </div>
      </div>

      <router-view/>

    </div>
  </div>
</template>

<style lang="scss" scoped>
  .header {
    display: flex;
    align-items: center;
    padding-left: 24px;
  }
  .logo {
    img {
      width: 64px;
      height: 64px;
    }
  }
</style>

<script>


export default {
  name: 'App',
  head: {
    link: [
        { href: 'https://fonts.googleapis.com/icon?family=Material+Icons', rel: 'stylesheet' },
        { href: 'https://fonts.googleapis.com/icon?family=Material+Icons+Outlined', rel: 'stylesheet' }, 
    ],
    script: [
    ]
  },
  created: function() {
  },
  data: function() {
    return {
      logo: require('./assets/ora-logo-440x440.png')
    }
  }
};
</script>