<template>
  <div>

    <div v-if="!checkout.order" class="spinner page-loader">
        <lottie-animation path="./spinner.json" />
    </div>

    <div class="order content" v-else>

      <div id="order-details">
        <div class="details-page">
          <div class="section">
            <!-- Order Confirmation -->
            <div class="section-header flex flex-v-center">
              <div class="success-icon">
                <span class="material-icons-outlined">done</span>
              </div>
              <div class="flex-1 flex-v-center">
                <p class="margin-0 text-sm">Order <span v-html="order.name"></span></p>
                <h3 class="margin-0">Thank you <span v-html="order.billing_address.first_name"></span>!</h3>
              </div>
            </div>
            <!-- Checkout Survey -->
            <div class="section-content">
              <CheckoutSurvey v-if="checkout.order" :order="order" :checkout="checkout"></CheckoutSurvey>
            </div>
          </div>
          <!-- Shipment Info -->
          <div class="section border-1 border-radius-4 margin-top-40 padding-24">
            <div class="section-header">
              <h3>Shipment Details</h3>
            </div>
            <div class="section-content">
              <div>You'll receive your shipment details via email shortly. Most orders ship within 24-48 hours.</div>
              <!-- <button v-on:click="trackPurchase()">track purchase</button> -->
            </div>
          </div>
          <!-- Customer Info -->
          <div class="section border-1 border-radius-4 margin-top-24 padding-24">
            <div class="section-header">
              <h3>Customer Information</h3>
            </div>
            <div class="section-content">
              <div class="flex">
                <div class="flex-1">
                  <div class="text-weight-md margin-bottom-8">Contact Information</div>
                  <div>{{ order.email }}</div>
                </div>
                <div class="flex-1">
                  <div class="text-weight-md margin-bottom-8">Payment Method</div>
                  <div>{{ order.gateway }}</div>
                </div>
              </div>
              <div class="flex margin-top-24">
                <div class="flex-1">
                  <div class="text-weight-md margin-bottom-8">Shipping Address</div>
                  <div class="address short padding-right-24">
                    <span v-if="order.shipping_address.company">{{ order.shipping_address.company }} / </span>
                    <span>{{ order.shipping_address.address1 }}</span>
                    <span v-if="order.shipping_address.address2">, {{ order.shipping_address.address2 }}</span>
                    <span>, {{ order.shipping_address.city }}</span>
                    <span v-if="order.shipping_address.province && showProvince(order.shipping_address)"> {{ order.shipping_address.province }}</span>
                    <span> {{ order.shipping_address.zip }}</span>
                    <span>, {{ order.shipping_address.country }}</span>
                  </div>
                </div>
                <div class="flex-1">
                  <div class="text-weight-md margin-bottom-8">Billing Address</div>
                  <div class="address short padding-right-24">
                    <span v-if="order.billing_address.company">{{ order.billing_address.company }} / </span>
                    <span>{{ order.billing_address.address1 }}</span>
                    <span v-if="order.billing_address.address2">, {{ order.billing_address.address2 }}</span>
                    <span>, {{ order.billing_address.city }}</span>
                    <span v-if="order.billing_address.province && showProvince(order.billing_address)"> {{ order.billing_address.province }}</span>
                    <span> {{ order.billing_address.zip }}</span>
                    <span>, {{ order.billing_address.country }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Cart Details -->
      <div id="cart-details">
        <div class="cart-items padding-bottom-24 margin-bottom-24 border-bottom-1">
          <div class="cart-item" v-for="item in checkout.items" v-bind:key="item.id">
            <div class="cart-item-image" v-bind:style="`background-image: url('${item.image}');`">
              <span class="cart-item-quantity" v-html="item.quantity"></span>
            </div>
            <div class="cart-item-details">
                <div class="cart-item-product-title" v-html="item.product_title"></div>
                <div class="cart-item-variant-title" v-html="item.variant_title"></div>
            </div>
            <div class="cart-item-price text-align-right">
              <div class="cart-item-line-price line-through original-price" v-if="item.original_line_price !== item.line_price" v-html="formatPrice(item.original_line_price)"></div>
              <div class="cart-item-line-price" v-html="formatPrice(item.line_price, true)"></div>
            </div>
          </div>
        </div>
        <div class="cart-totals">
          <div class="cart-subtotal flex margin-bottom-16">
            <label class="flex-1">Subtotal</label>
            <div v-html="formatPrice(checkout.original_total_price/100)" class="flex-1 text-align-right"></div>
          </div>
          <div class="cart-shipping flex margin-bottom-16">
            <label class="flex-1">Shipping</label>
            <div v-if="order.total_shipping_price_set.presentment_money.amount" v-html="formatPrice(order.total_shipping_price_set.presentment_money.amount)" class="flex-1 text-align-right"></div>
            <div v-else>-</div>
          </div>
          <div v-if="checkout.discountAmount > 0" class="cart-discount flex margin-bottom-16">
            <label class="flex-1">Discounts</label>
            <div v-if="checkout.discountAmount" v-html="`-${formatPrice(checkout.discountAmount)}`" class="flex-1 text-align-right"></div>
            <div v-else>-</div>
          </div>
          <div class="cart-total flex text-weight-md border-top-1 padding-top-24 text-lg">
            <label class="flex-1">Total</label>
            <div v-html="formatPrice(cartTotal())" class="flex-1 text-align-right"></div>
          </div>
        </div>
      </div>

    </div>

  </div>
</template>

<style scoped lang="scss">
  @media (max-width: 1000px) {
    .checkout .content .details-page .actions {
      align-items: flex-start;
    }
  }
</style>


<script>

  import { firebase } from "@firebase/app"
  // import { functions } from "@/firebase";
  import CheckoutSurvey from "./CheckoutSurvey.vue";
  import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";
  import Analytics from 'analytics'
  import googleTagManager from '@analytics/google-tag-manager'

  var analytics = Analytics({
    app: 'ora-checkout',
    plugins: [
      googleTagManager({
        containerId: 'GTM-W3XJP9M'
      })
    ]
  })

  analytics.page()

  // var shopify = functions.httpsCallable('shopify');

  export default {
    name: 'Order',
    props: {
      order_id: String
    },
    components: {
      CheckoutSurvey,
      LottieAnimation
    },
    data: function() {
      return {
        checkout: {},
        countriesWithProvinces: [ 
          "United States",
          "Canada",
          "Australia" 
        ],
        currencySymbols: {
          "GBP": "£",
          "USD": "$",
          "CAD": "$"
        },
        order: {}
      }
    },
    created: async function() {

      var vm = this;

      this.checkout = (await firebase.findCheckout(Number(this.$route.params.id)))[0];
      this.order = this.checkout.order;
      console.log("this.order",this.order);
      console.log("this.checkout",this.checkout);

      vm.trackPurchase();

    },
    methods: {
      showProvince: function (address) {
        return this.countriesWithProvinces.indexOf(address.country) > -1;
      },
      formatPrice: function (price, isLine){
        if (isLine) {
          return `${this.currencySymbols[this.checkout.order.currency]}${(Number(price)/100).toFixed(2)}`;
        }
        return `${this.currencySymbols[this.checkout.order.currency]}${Number(price).toFixed(2)}`;
      },
      cartTotal: function () {
        return this.order.total_price;
      },
      trackPurchase: function() {

        if (localStorage.getItem('OraOrganicTransactionId') !== null) {
          console.log("Transaction has already been tracked, don't track this purchase.")
        } else {
          var trackingData = {
            "event": "dl_purchase",
            "user_properties": {
              "customer_email": this.order.customer.email,
              "customer_id": this.order.customer.id,
              "customer_address_1": this.order.customer.default_address.address1,
              "customer_address_2": this.order.customer.default_address.address2,
              "customer_city": this.order.customer.default_address.city,
              "customer_country": this.order.customer.default_address.country_code,
              "customer_province": this.order.customer.default_address.province_code,
              "customer_zip": this.order.customer.default_address.zip,
            },
            "ecommerce": {
              "currencyCode": this.order.presentment_currency,
              "purchase": {
                "actionField": {
                  "id": this.order.id,
                  "sub_total": this.order.subtotal_price
                },
                "products": this.order.line_items.map(function(item) {
                  return {
                    "variant_id": item.variant_id,
                    "name": item.title,
                    "category": "Health & Wellness",
                    "price": item.price,
                    "quantity": item.quantity
                  }
                })
              }
            }
          }

          window.dataLayer.push(trackingData);

          localStorage.setItem('OraOrganicTransactionId', this.order.id);

          console.log("track purchase:",trackingData);
        }

        

      },
    }

  };

</script>