<template>
  <div>

    <div v-if="loading" class="spinner page-loader">
      <lottie-animation path="./spinner.json" />
    </div>

    <div v-else>

      <div id="checkout" v-if="checkoutStatus != 'complete'" class="content">

        <!-- Customer Details -->
        <div id="customer-details">

          <!-- Address -->
          <div v-if="form.detailsPage === 'address'" id="address" class="details-page">
            <div class="section">

              <div class="section-header">
                <h3>Contact information</h3>
              </div>
              <div class="section-content">
                <div class="form">
                  <div v-bind:class="[ 'input-group', { hasData: form.customer.email }, 'full-width', 'margin-bottom-0' ]">
                    <label>Email</label>
                    <input type="email" placeholder="Email" v-model="form.customer.email" class="full-width" v-on:blur="checkForShopifyCustomer(form.customer.email)"/>
                  </div>
                  <div class="input-group checkbox label-outside margin-top-8">
                    <input type="checkbox" name="accepts_marketing" v-model="form.customer.accepts_marketing" checked/>
                    <label class="margin-top-8">Keep me up to date on news and exclusive offers</label>
                  </div>
                </div>
              </div>
            </div>
            <div class="section">
              <div class="section-header">
                <h3>Shipping Information</h3>
              </div>
              <div class="section-content">
                <div class="form">
                  <Address v-if="form.customer" v-bind:address="form.customer.shipping_address" @addressUpdated="isValidAddress"></Address>
                </div>
              </div>
            </div>

            <div class="actions">
              <a class="text-link" href="https://oraorganic.myshopify.com/cart">Back to cart</a>
              <button class="button button--solid button--kia" v-on:click="goToPage('shipping');" :class="{ disabled: !isValidAddress(form.customer.shipping_address) }">Continue to shipping</button>

            </div>
          </div>

          <!-- Shipping -->
          <div v-if="form.detailsPage === 'shipping'" id="shipping" class="details-page">

            <div class="section margin-bottom-40">
              <div class="section-content">
                <div class="table">
                  <div class="table-row flex">
                    <label class="flex-1">Contact</label>
                    <div v-html="form.customer.email" class="flex-3"></div>
                    <button class="text-link text-sm" v-on:click="goToPage('address');">Change</button>
                  </div>
                  <div class="table-row flex" v-if="form.customer.shipping_address">
                    <label class="flex-1">Ship to</label>
                    <div class="flex-3">
                      <span v-if="form.customer.shipping_address.company">{{ form.customer.shipping_address.company }} / </span>
                      <span>{{ form.customer.shipping_address.address1 }}</span>
                      <span v-if="form.customer.shipping_address.address2">, {{ form.customer.shipping_address.address2 }}</span>
                      <span>, {{ form.customer.shipping_address.city }}</span>
                      <span v-if="form.customer.shipping_address.province && showProvince(form.customer.shipping_address)"> {{ form.customer.shipping_address.province }}</span>
                      <span> {{ form.customer.shipping_address.zip }}</span>
                      <span>, {{ form.customer.shipping_address.country }}</span>
                    </div>
                    <button class="text-link text-sm padding-0" v-on:click="goToPage('address')">Change</button>
                  </div>
                </div>
              </div>
            </div>

            <div class="section">
              <div class="section-header">
               <h3>Shipping method</h3>
              </div>
              <div class="section-content">
                <div class="radio-input table">
                  <div v-if="hasShippingRates === true">
                    <div v-for="(option, index) in form.shippingOptions" v-bind:key="option.value" class="input-group table-row radio">
                      <input type="radio" v-model="form.shippingOption" v-bind:value="option" v-bind:checked="index === 0"/>
                      <label>{{ option.title }}</label>
                      <label class="flex-1 text-align-right" v-html="formatPrice(option.price)"></label>
                    </div>
                  </div>

                  <div v-else-if="hasShippingRates === false" class="table-row">
                    <div class="flex flex-v-center">
                      <div class="spinner width-32 height-32">
                        <lottie-animation path="./spinner.json" />
                      </div>
                      <div class="flex-1 margin-left-24"> Getting shipping rates...</div>
                    </div>
                  </div>

                  <div v-else class="table-row">
                    <div class="flex-1"> Failed to get shipping rates. Please check your shipping address.</div>
                  </div>
                </div>
              </div>
            </div>

            <div class="actions">
              <button class="text-link" v-on:click="goToPage('address');">Back to customer details</button>
              <button v-bind:class="[ 'button button--solid button--kia', { disabled: hasShippingRates !== true } ]" v-on:click="goToPage('payment'); loadDropInUI();">Continue to payment</button>
            </div>

          </div>

          <!-- Payment -->
          <div v-if="form.detailsPage === 'payment'" id="payment" class="details-page">

            <!-- Saved details -->
            <div class="section">
              <div class="section-content">
                <div class="table">
                  <div class="table-row flex">
                    <label class="flex-1">Contact</label>
                    <div v-html="form.customer.email" class="flex-3"></div>
                    <button class="text-link text-sm" v-on:click="goToPage('address');">Change</button>
                  </div>
                  <div class="table-row flex" v-if="form.customer.shipping_address">
                    <label class="flex-1">Ship to</label>
                    <div class="flex-3">
                      <span v-if="form.customer.shipping_address.company">{{ form.customer.shipping_address.company }} / </span>
                      <span>{{ form.customer.shipping_address.address1 }}</span>
                      <span v-if="form.customer.shipping_address.address2">, {{ form.customer.shipping_address.address2 }}</span>
                      <span>, {{ form.customer.shipping_address.city }}</span>
                      <span v-if="form.customer.shipping_address.province && showProvince(form.customer.shipping_address)"> {{ form.customer.shipping_address.province }}</span>
                      <span> {{ form.customer.shipping_address.zip }}</span>
                      <span>, {{ form.customer.shipping_address.country }}</span>
                    </div>
                    <button class="text-link text-sm padding-0" v-on:click="goToPage('address');">Change</button>
                  </div>
                  <div class="table-row flex">
                    <label class="flex-1">Method</label>
                    <div v-html="form.shippingOption.title" class="flex-3"></div>
                    <button class="text-link text-sm" v-on:click="goToPage('shipping')">Change</button>
                  </div>
                </div>
              </div>
            </div>

            <!-- Discount -->
            <div id="discount-entry" class="section margin-top-40">
              <div class="section-header margin-bottom-24">
                <h3>Gift card or discount code</h3>
              </div>
              <div class="section-content">
                <div v-if="form.checkout" v-bind:class="[ 'discount-code padding-bottom-24 margin-bottom-24 border-bottom-1' ]">

                  <!-- Discount: Applied -->
                  <div v-if="discountStatus === 'applied'" class="full-width flex">
                    <div v-bind:class="[ 'input-group', 'full-width', 'flex-1 flex flex-v-center', 'margin-bottom-0', 'hasData', 'discount applied' ]">
                      <label>Applied discount</label>
                      <div class="flex flex-v-center full-width">
                        <span class="text-weight-md margin-top-16 flex-1" v-html="`${checkout.discount.title}`"></span>
                        <span class="material-icons-outlined padding-right-16 text-md">local_offer</span>
                      </div>
                    </div>
                    <div class="actions" style="margin-top: 0;">
                      <button v-bind:class="[ 'button button--kia button--solid', discountStatus, 'flex flex-v-center padding-24' ]" v-on:click="removeDiscount(checkout.discount.title)" style="padding: 12px;">
                        <span v-if="discountStatus === 'applied'" class="material-icons-outlined">delete</span>
                        <span v-else class="spinner">
                          <lottie-animation path="./spinner.json" />
                        </span>
                      </button>
                    </div>
                  </div>

                  <!-- Discount: Not Applied -->
                  <div v-else class="flex full-width">
                    <div v-bind:class="[ 'input-group', { hasData: checkout.discount }, 'full-width', 'flex-1', 'margin-bottom-0' ]">
                      <label>Discount code</label>
                      <input type="text" id="discount-code" v-model="form.discount_code" placeholder="Enter discount code" class="full-width">
                    </div>
                    
                    <div class="actions" style="margin-top: 0;">
                      <button v-bind:class="[ 'button button--kia button--solid', discountStatus, 'flex flex-v-center padding-0', { disabled: !form.discount_code } ]" v-on:click="applyDiscount(form.discount_code)" style="padding: 4px 8px;">
                        <span v-if="discountStatus === 'open' || discountStatus.indexOf('Error') > -1" class="margin-top-4 margin-bottom-0 margin-left-16 margin-right-16" >Apply</span>
                        <span v-else class="spinner">
                          <lottie-animation path="./spinner.json" />
                        </span>
                      </button>
                    </div>
                  </div>

                  <!-- Discount: Error -->
                  <div v-if="discountStatus.indexOf('Error') > -1" class="errors full-width flex">
                    <div class="error margin-top-16" v-html="discountStatus"></div>
                  </div>

                </div>
              </div>
            </div>

            <!-- Payment options -->
            <div id="payment-options" class="section margin-top-40">
              <div class="section-header margin-bottom-24">
                <h3>Payment</h3>
                <div>All transactions are secured and encrypted.</div>
              </div>
              <div class="section-content">
                <div id="dropin-container">
                </div>
              </div>
            </div>

            <!-- Billing address -->
            <div id="billing-address" class="section margin-top-40">
              <div class="section-header margin-bottom-24">
               <h3>Billing address</h3>
               <div>Select the address that matches your card or payment method.</div>
              </div>
              <div class="section-content">
                <div class="radio-input table">
                  <div class="input-group table-row radio">
                    <input type="radio" name="sameBillingAddress" v-model="sameBillingAddress" value="true" checked />
                    <label>Use the same billing address</label>
                  </div>
                  <div class="input-group table-row radio">
                    <input type="radio" name="sameBillingAddress" v-model="sameBillingAddress" value="false" />
                    <label>Use a different billing address</label>
                  </div>
                </div>
              </div>
            </div>

            <div v-if="sameBillingAddress === 'false'" class="section margin-top-40">
              <div class="section-header">
                <h3>Billing information</h3>
              </div>
              <div class="section-content">
                <Address v-if="form.customer" v-bind:address="form.customer.billing_address"></Address>
              </div>
            </div>

            <div class="actions">
              <button class="text-link" v-on:click="goToPage('shipping');">Back to shipping method</button>
              <button 
                v-on:click="confirmPurchase()" 
                v-bind:class="[ 'button button--kia', 'button--solid', checkoutStatus, { 'disabled': !paymentMethodRequestable }, 'padding-left-24 padding-right-24 padding-top-0 padding-bottom-0' ]">
                <span v-if="checkoutStatus === 'processing'" class="spinner width-32 height-32"><lottie-animation path="./spinner.json" /></span>
                <span v-else>Confirm Purchase</span>
              </button>
            </div>

            <div class="error width-300 margin-top-24 margin-left-auto" v-if="checkoutStatus === 'error'" v-html="checkout.error"></div>

          </div>

        </div>

        <!-- Cart Details -->
        <div id="cart-details" v-bind:class="{ open: mobileCartVisible }">
          <div v-on:click="showMobileCart()" v-bind:class="[ 'cart-header flex flex-v-center flex-sp-between display-mob', { open: mobileCartVisible } ]">
            <div class="margin-top-4"><span class="text-weight-md">Your cart:</span> {{ checkout.items.length }} Items | <span v-html="formatPrice(cartTotal())"></span></div>
            <span class="icon material-icons-outlined">expand_more</span>
          </div>
          <div v-bind:class="[ 'cart-items padding-bottom-24 margin-bottom-24 border-bottom-1' ]">
            <div class="cart-item" v-for="item in checkout.items" v-bind:key="item.id">
              <div class="cart-item-image" v-bind:style="`background-image: url('${item.image}');`">
                <span class="cart-item-quantity" v-html="item.quantity"></span>
              </div>
              <div class="cart-item-details">
                  <div class="cart-item-product-title" v-html="item.product_title"></div>
                  <div class="cart-item-variant-title" v-html="item.variant_title"></div>
              </div>
              <div class="cart-item-price text-align-right">
                <div class="cart-item-line-price line-through original-price" v-if="item.original_line_price !== item.line_price" v-html="formatPrice(item.original_line_price)"></div>
                <div class="cart-item-line-price" v-html="formatPrice(item.line_price)"></div>
              </div>
            </div>
          </div>
          <div v-if="form.checkout && form.detailsPage != 'payment'" v-bind:class="[ 'discount-code padding-bottom-24 margin-bottom-24 border-bottom-1' ]">

            <!-- Discount: Applied -->
            <div v-if="discountStatus === 'applied'" class="full-width flex">
              <div v-bind:class="[ 'input-group', 'full-width', 'flex-1 flex flex-v-center', 'margin-bottom-0', 'hasData', 'discount applied' ]">
                <label>Applied discount</label>
                <div class="flex flex-v-center full-width">
                  <span class="text-weight-md margin-top-16 flex-1" v-html="`${checkout.discount.title}`"></span>
                  <span class="material-icons-outlined padding-right-16 text-md">local_offer</span>
                </div>
              </div>
              <div class="actions">
                <button v-bind:class="[ 'button button--kia button--solid', discountStatus, 'flex flex-v-center padding-16' ]" v-on:click="removeDiscount(checkout.discount.title)">
                  <span v-if="discountStatus === 'applied'" class="material-icons-outlined">delete</span>
                  <span v-else class="spinner">
                    <lottie-animation path="./spinner.json" />
                  </span>
                </button>
              </div>
            </div>

            <!-- Discount: Not Applied -->
            <div v-else class="flex full-width">
              <div v-bind:class="[ 'input-group', { hasData: checkout.discount }, 'full-width', 'flex-1', 'margin-bottom-0' ]">
                <label>Discount code</label>
                <input type="text" id="discount-code" v-model="form.discount_code" placeholder="Enter discount code" class="full-width">
              </div>
              
              <div class="actions">
                <button v-bind:class="[ 'button button--kia button--solid', discountStatus, 'flex flex-v-center padding-0', { disabled: !form.discount_code } ]" v-on:click="applyDiscount(form.discount_code)">
                  <span v-if="discountStatus === 'open' || discountStatus.indexOf('Error') > -1" class="margin-top-4 margin-bottom-0 margin-left-16 margin-right-16">Apply</span>
                  <span v-else class="spinner">
                    <lottie-animation path="./spinner.json" />
                  </span>
                </button>
              </div>
            </div>

            <!-- Discount: Error -->
            <div v-if="discountStatus.indexOf('Error') > -1" class="errors full-width flex">
              <div class="error margin-top-16" v-html="discountStatus"></div>
            </div>

          </div>
          <div v-bind:class="[ 'cart-totals' ]">
            <div class="cart-subtotal flex margin-bottom-16">
              <label class="flex-1">Subtotal</label>
              <div v-html="formatPrice(checkout.original_total_price/100)" class="flex-1 text-align-right"></div>
            </div>
            <div class="cart-shipping flex margin-bottom-16">
              <label class="flex-1">Shipping</label>
              <div v-if="form.shippingOption.price" v-html="formatPrice(form.shippingOption.price)" class="flex-1 text-align-right"></div>
              <div v-else>-</div>
            </div>
            <div v-if="checkout.discountAmount > 0" class="cart-discount flex margin-bottom-16">
              <label class="flex-1">Discounts</label>
              <div v-if="checkout.discountAmount" v-html="`-${formatPrice(checkout.discountAmount)}`" class="flex-1 text-align-right"></div>
              <div v-else>-</div>
            </div>
            <div class="cart-total flex text-weight-md border-top-1 padding-top-24 text-lg">
              <label class="flex-1">Total</label>
              <div v-html="formatPrice(cartTotal())" class="flex-1 text-align-right"></div>
            </div>
          </div>
        </div>

      </div>

    </div>

  </div>
</template>

<script>

var axios = require('axios');

import { firebase } from "@firebase/app"
import { functions } from "@/firebase";
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";
import Analytics from 'analytics'
import googleTagManager from '@analytics/google-tag-manager'

const analytics = Analytics({
  app: 'ora-checkout',
  plugins: [
    googleTagManager({
      containerId: 'GTM-W3XJP9M'
    })
  ]
})

analytics.page()
  
// Generic Functions
var dropin = require('braintree-web-drop-in');
var braintree = functions.httpsCallable('braintree');
var shopify = functions.httpsCallable('shopify');

// Action Functions
var createShopifyOrder = functions.httpsCallable('createShopifyOrder');
// var createRechargeSubscription = functions.httpsCallable('createRechargeSubscription');
var getShopifyOrders = functions.httpsCallable('getShopifyOrders');
var createShopifyCheckout = functions.httpsCallable('createShopifyCheckout');
var getShopifyCheckout = functions.httpsCallable('getShopifyCheckout');
var updateShippingShopifyCheckout = functions.httpsCallable('updateShippingShopifyCheckout');
var getShopifyDiscountLocation = functions.httpsCallable('getShopifyDiscountLocation');
var getShopifyPriceRule = functions.httpsCallable('getShopifyPriceRule');
// var disassociateCheckout = functions.httpsCallable('disassociateCheckout');
// var applyDiscountShopifyCheckout = functions.httpsCallable('applyDiscountShopifyCheckout');


var isSandbox = true;
var vm;

import Address from "./Address.vue";

export default {
  name: 'Checkout',
  props: {
    cart: Object
  },
  components: {
    Address,
    LottieAnimation
  },
  data: () => {
    return {
      braintreeCustomer: {},
      checkout: {
        currency: "GBP"
      },
      checkoutId: undefined,
      checkoutOrder: undefined,
      checkoutStatus: 'open', // open, processing, complete
      countriesWithProvinces: [ 
        "United States",
        "Canada",
        "Australia" 
      ],
      currencySymbols: {
        "GBP": "£",
        "USD": "$",
        "CAD": "$"
      },
      discountStatus: 'open', // open, processing, applied
      dropinUI: undefined,
      form: {
        detailsPage: "",
        customer: {
          shipping_address: {},
          billing_address: {},
          email: undefined
        },
        shippingOption: {},
        shippingOptions: []
      },
      hasShippingRates: false,
      loading: true,
      locale: "en_US",
      location: "GB",
      merchantAccountId: "ora_Instant",
      mobileCartVisible: false,
      order: {},
      paymentMethodRequestable: false,
      sameBillingAddress: true,
      selectedPaymentMethod: undefined,
      shopifyCustomer: {},
      storage: { key: 'form', days: 30},
      tempBillingAddress: {},
      trackedEvents: []
    }
  },
  computed: {

  },  
  beforeCreate: () => {

  },
  created: async function() {

    // Init the cart and checkout
    vm = this;

    await this.getLocation();
    await this.getCheckout();
    await this.getDiscounts();

    // var savedForm = this.getSavedData('form');
    var savedForm;

    isSandbox = this.$route.query.sandbox ? true : false;

    console.log("is sandbox",isSandbox);
    // console.log("vm.checkout",this.checkout);

    if (this.checkout.order) {
      vm.order = this.checkout.order;
      this.checkoutStatus = 'complete';

      if (vm.order.id) {
        this.$router.push({ name: 'Order', params: { id: vm.order.id, order: vm.order, checkout: vm.checkout } });
      }
    }

    // Discount Code
    if (this.checkout.discount.title) { 
      // this.discountStatus = 'applied'; 
      this.removeDiscount();
    }


    if (this.form.email) {
      this.shopifyCustomer = await this.getShopifyCustomer();

      if (savedForm) { this.form = savedForm; }

      // console.log("form:",this.form);
      this.loading = false;

      // Check Local Storage for data
      if (this.shopifyCustomer.id) {

        this.form.customer.email = this.shopifyCustomer.email;
        this.form.customer.id = this.shopifyCustomer.id;
        
        if (!savedForm) { this.form.customer.shipping_address = this.shopifyCustomer.default_address; }

        this.braintreeCustomer = await this.getBraintreeCustomer(this.shopifyCustomer.id);

        if (!this.braintreeCustomer.id) {
          this.braintreeCustomer = await this.createBraintreeCustomer({
            id: this.shopifyCustomer.id,
            email: this.shopifyCustomer.email,
            firstName: this.shopifyCustomer.first_name,
            lastName: this.shopifyCustomer.last_name
          })
        }

        if (this.form.detailsPage === 'payment') {
          this.loadDropInUI();
        }

        // console.log("braintreeCustomer:",this.braintreeCustomer);

      }

    } else {

      if (savedForm) { 
        this.form = savedForm; 
      } else {
        this.form.detailsPage = 'address';  
      }

      // console.log("form:",this.form);

      if (this.form.detailsPage === 'payment' && this.checkoutStatus === 'open') {
        this.loadDropInUI();
      }
      
      this.loading = false;

    }
    
    // console.log("loading:",this.loading);
    vm.checkout.items = vm.checkout.items.map(y => {
      y.is_subscription = y.properties.subscription_id !== undefined;
      y.properties = Object.entries(vm.checkout.items[0].properties).map(x => { var obj = { name: x[0], value: x[1] }; return obj; });
      return y;
    })
    // console.log("[created] lineItemProperties:",vm.checkout.items);

  },
  methods: {
    /* Localization */
    getLocation: () => {
      if (vm.location === 'GB') {
        vm.merchantAccountId = "ora_GBP";
        vm.locale = "en_GB";
      }
    },

    /* Local Storage */
    setSavedData: function(key, value) {

      if (value) {

        value.timestamp = new Date().getTime();
        localStorage.setItem(key, JSON.stringify(value));  

      } else {
        if (key == undefined) { key = vm.storage.key; }
        vm[key].timestamp = new Date().getTime();

        if (vm[key] !== "undefined" && vm[key] !== undefined) {
          // console.log("Key:",this[key]);
            localStorage.setItem(key, JSON.stringify(vm[key]));  
        } else {
          // console.log({"Error": `${key} is undefined`});
        }
      }

      
    },
    getSavedData: function(key) {
      if (key == undefined) { key = vm.storage.key; }
      var data = localStorage.getItem(key);
      if (data !== "undefined" && data !== undefined && data !== null) {
        var jsonData = JSON.parse(data);
        var now = new Date().getTime();
        var max_timestamp = now - (vm.storage.days * 24 * 60 * 60 * 1000);
        if (max_timestamp > jsonData.timestamp) {
          jsonData = null;
          vm.removeSavedData();
        }
      }
      return jsonData;
    },
    removeSavedData: function(key) { if (key == undefined) { key = vm.storage.key; localStorage.removeItem(key); } },
    restoreSavedData: function(key, value) { vm[key] = value; },

    /* Helpers */
    formatSnakeCase: (text) => {
      return text.replace(/_./g, letter => { return letter.toUpperCase().replace('_',''); });
    },
    formatPrice: (price) => {
      return `${vm.currencySymbols[vm.checkout.currency]}${Number(price).toFixed(2)}`;
    },
    validateEmail: (email) => {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      // console.log("valid email:",re.test(email))
      return re.test(email);
    },
    validateZip: (address) => {
      if (!address.zip) {
        return false;
      } else if (address.country === "United Kingdom") {
        // console.log("[validateZip] address:",address);
        const re = /([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})/;
        return re.test(address.zip);
      } else {
        return true;
      }
    },
    sleep: (milliseconds) => {
      const date = Date.now();
      let currentDate = null;
      do {
        currentDate = Date.now();
      } while (currentDate - date < milliseconds);
    },
    showProvince: (address) => {
      return vm.countriesWithProvinces.indexOf(address.country) > -1;
    },
    isValidAddress: (address) => {
      // console.log("[isValidAddress] address:",address);
      // console.log("[validateZip] address:",vm.validateZip(address));
      if (
          vm.validateEmail(vm.form.customer.email) &&
          address.first_name &&
          address.last_name &&
          address.address1 &&
          address.city &&
          address.country &&
          address.province &&
          vm.validateZip(address)) {
        return true;
      } else {
        return false;
      }
    },


    /* Create Drop in UI */
    createDropInUI: async () => {

      var authorization;
      
      if (vm.braintreeCustomer && vm.braintreeCustomer.clientToken) {
        authorization = vm.braintreeCustomer.clientToken;
      } else {
        authorization = isSandbox === false ? "production_zjs9xy48_qt9bh44gy8sq2396" : "sandbox_gprkn3fx_2jz43xq6xtty7nmz" ;
      }

      if (!authorization) { return { "Error": "Must provide an authorization token!" }}

      // console.log("cart total:",vm.cartTotal());

      await dropin.create({
        authorization: authorization,
        container: '#dropin-container',
        card: {
          cardholderName: {
            required: true
            // to make cardholder name required
            // required: true
          }
        },
        // paypal: {
        //   flow: 'checkout',
        //   amount: Number(vm.cartTotal()),
        //   currency: vm.checkout.currency,
        //   buttonStyle: {
        //     size: 'large'
        //   }
        // },
        // venmo: {
        //   allowNewBrowserTab: false
        // },
        // applePay: {
        //   displayName: 'Ora Organic',
        //   paymentRequest: {
        //     total: {
        //       label: 'Ora Organic',
        //       amount: vm.cartTotal()
        //     },
        //     // We recommend collecting billing address information, at minimum
        //     // billing postal code, and passing that billing postal code with all
        //     // Apple Pay transactions as a best practice.
        //     requiredBillingContactFields: ["postalAddress"]
        //   }
        // },
        overrides: {
          styles: {
            input: {
              'font-family': `'TT Commons'`
            }
          }
        },
        locale: vm.locale
      }).then((instance) => {

        // instance.on('paymentOptionSelected', function () {
        //   // console.log("paymentOptionSelected:",event);
        // })
        instance.on('paymentMethodRequestable', function () {
          // console.log("paymentMethodRequestable:",event);
          vm.paymentMethodRequestable = true;
        })

        vm.dropinUI = instance;

      })

    },
    loadDropInUI: async () => {

      // await vm.checkForBraintreeCustomer();
      await vm.createDropInUI();
      // this.configureDropInUI(this.dropinUI);

      // if (vm.braintreeCustomer.paymentMethods.length > 0) {
      //   vm.selectedPaymentMethod = vm.braintreeCustomer.paymentMethods[0];
      //   vm.paymentMethodRequestable = true;
      // }

      // console.log("dropinUI:",vm.dropinUI)
      // console.log("paymentOptions:",vm.dropinUI.getAvailablePaymentOptions());

    },
    configureDropInUI: (instance) => {

      if (!instance) { return { "Error": "Must provide a dropinUI instance!" }}


    },

    /* Navigation */
    goToPage: (key) => {
      vm.form.detailsPage = key;
      vm.setSavedData('form');
    },
    showMobileCart: () => {
      vm.mobileCartVisible ? vm.mobileCartVisible = false : vm.mobileCartVisible = true; window.scrollTo(0,0);
    },


    /* Customer Methods */
    getBraintreeCustomer: (id) => {
      return braintree({ action: 'getBraintreeCustomer', customerId: id, sandbox: isSandbox }).then((response) => { return response.data; }).catch(() => { return undefined; });
    },
    createBraintreeCustomer: (data) => {
      return braintree({ action: 'createBraintreeCustomer', body: data, sandbox: isSandbox }).then((response) => { return response.data; }).catch((err) => { return { "error creating braintree customer": err } });
    },
    checkForShopifyCustomer: async (email) => {
      if (vm.validateEmail(email)) {
        var customer = await vm.getShopifyCustomer(email);
        if (customer) {
          // console.log("Existing customer:",customer)
          vm.form.shopifyCustomer = customer;
        } else {
          // console.log("No customer found");
        }
      }
    },
    checkForBraintreeCustomer: async () => {
      if (vm.form.shopifyCustomer.id) {
        var customer = await vm.getBraintreeCustomer(vm.form.shopifyCustomer.id);
        if (customer) {
          // console.log("Existing customer:",customer)
          vm.braintreeCustomer = customer;
        } else {
          // console.log("No customer found");
        }
      }
    },
    getShopifyCustomer: (email) => {
      return shopify({
        method: 'GET',
        url: `https://oraorganic.myshopify.com/admin/api/2021-01/customers/search.json?query=email:${email}`
      }).then((response) => {
        return response.data.customers[0];
      }).catch(() => {
        return "Shopify customer not found";
      })
    },
    getShopifySavedSearch: (saved_search_id) => {
      return shopify({
        method: 'GET',
        url: `https://oraorganic.myshopify.com/admin/api/2021-04/customer_saved_searches/${saved_search_id}/customers.json`
      }).then((response) => {
        return response.data.customers.map(x => x.email);
      }).catch(() => {
        return "Shopify saved search not found";
      })
    },
    getShopifyCountries: () => {
      return shopify({
        method: 'GET',
        url: `https://oraorganic.myshopify.com/admin/api/2021-04/countries.json`
      }).then((response) => {
        return response.data.countries;
      }).catch(() => {
        return "Shopify countries not found";
      })
    },

    /* Payment Methods */
    clearSelectedPaymentMethod: (instance) => {
      instance.clearSelectedPaymentMethod();
    },
    getPaymentMethod: (token) => {
      return braintree({ action: 'getPaymentMethod', token: token }).catch((err) => { return { "error getting payment method": err }});
    },
    createPaymentMethod: (id, nonce) => {
      return braintree({ action: 'createPaymentMethod', customerId: id, paymentMethodNonce: nonce }).catch((err) => { return { "error creating payment method": err }});
    },
    requestPaymentMethod: async (instance) => {
      // console.log("vm",vm);
      return await instance.requestPaymentMethod().then(function (response) {
        // console.log("payload:",response);
        return response;
        // var paymentMethod = await vm.createPaymentMethod(vm.braintreeCustomer.id, payload.nonce);
        // console.log("paymentMethod:",paymentMethod)
      });
    },

    /* Shipping Methods */
    getShippingRates: async () => {

      vm.hasShippingRates = false;

      var orderData = {
        cart: vm.checkout,
        customer: vm.form.customer,
        shippingOption: vm.form.shippingOption,
        tags: ["International"]
      };

      // Check if order is a subscription
      if (vm.checkout.items.findIndex(x => x.is_subscription === true) > -1) {
        orderData.tags = ["International","Subscription","Subscription First Order"];
      }

      console.log("orderData:",orderData.tags);

      if (orderData.tags.indexOf('Subscription') > -1) {

        var shippingZones = await vm.getShippingZones();
        var shippingZone = shippingZones.filter(x => x.name === orderData.customer.shipping_address.country)[0];
        var shippingRates = shippingZone.price_based_shipping_rates;

        vm.form.shippingOptions = [];

        for (var i in shippingRates) {

          var cartTotalPrice = orderData.cart.total_price/100;
          var shippingRate = shippingRates[i];
          var shippingMinimum = shippingRate.min_order_subtotal != null ? Number(shippingRate.min_order_subtotal) : 0;
          var shippingMaximum = shippingRate.max_order_subtotal != null ? Number(shippingRate.max_order_subtotal) : 1000000;

          console.log("shippingMinimum:",shippingMinimum);
          console.log("shippingMaximum:",shippingMaximum);
          console.log("cartTotalPrice:",cartTotalPrice);

          if (cartTotalPrice >= shippingMinimum && cartTotalPrice < shippingMaximum) {
            vm.form.shippingOptions.push({
              "title": shippingRate.name,
              "price": Number(shippingRate.price),
              "original_price": Number(shippingRate.price)
            })
          }

        }

        console.log("Shipping Rates:",vm.form.shippingOptions);

        vm.form.shippingOption = vm.form.shippingOptions[0];
        vm.hasShippingRates = vm.form.shippingOptions.length > 0;


      } else {
        if (vm.form.checkout === undefined || vm.form.checkout.Errors) {
          vm.form.checkout = await vm.createShopifyCheckout(orderData);
          // console.log("Checkout Created:",vm.form.checkout);
        } else {
          await updateShippingShopifyCheckout({ checkout_id: vm.form.checkout.id, shipping_address: vm.form.customer.shipping_address});
          // Wait for shipping rates to populate
          await vm.sleep(2000);
          vm.form.checkout = await vm.getShopifyCheckout({ checkout: vm.form.checkout})
            .then((response) => { return response; })
            .catch((err) => { return err;  });
          
        }

        if (vm.form.checkout.availableShippingRates) {
          vm.form.shippingOptions = vm.form.checkout.availableShippingRates.shippingRates.map(x => {
            return {
              "title": x.title,
              "price": x.priceV2.amount,
              "original_price": x.priceV2.amount
            }

          })

          vm.form.shippingOption = vm.form.shippingOptions[0];
          vm.hasShippingRates = true;
          vm.setSavedData('form');
        } else {
          vm.hasShippingRates = 'Error'; 
        }
      }

      console.log("vm.hasShippingRates:",vm.hasShippingRates);

      

    },

    getShippingZones: async () => {
      
      var shippingZones = (await shopify({
        "method": "GET",
        "url": "https://oraorganic.myshopify.com/admin/api/2021-10/shipping_zones.json"
      })).data.shipping_zones;

      // console.log("[Shopify] shippingZones:",shippingZones);
      return shippingZones;

    },

    /* Discount methods */
    getDiscounts: () => {

      
      // If we haven't applied a discount, do nothing
      if (!vm.checkout.discount.title) {
        vm.checkout.discountAmount = 0;

        vm.checkout.items.forEach((item) => {
          vm.checkout.discountAmount += (item.line_level_total_discount/100);
        })
      }
      

    },
    applyDiscount: async (discount) => {

      vm.discountStatus = 'processing';

      var data = {
        checkout_id: vm.form.checkout.id,
        discount_code: discount
      }

      // console.log("apply discount:",data);

      var discount_code = await getShopifyDiscountLocation(data).then((response) => {
        return response.data.discount_code;
      }).catch(() => {
        // console.log("Error getting discount:",err);
        vm.discountStatus = 'error';
      });

      var price_rule = await getShopifyPriceRule({ price_rule_id: discount_code.price_rule_id }).then((response) => {
        return response.data.price_rule;
      }).catch(() => {
        // console.log("Error getting price rule:",err);
        vm.discountStatus = 'error';
      });

      // console.log("discount_code:",discount_code);
      // console.log("price_rule:",price_rule);

      vm.checkout.discount = price_rule;
      vm.checkout.discountAmount = vm.checkout.total_discount/100;

      // Handle prerequisites
      var validCart = true;

      // Check Usage
      if (price_rule.usage_limit) {
        if (discount_code.usage_count >= price_rule.usage_limit) {
          validCart = false;
          vm.discountStatus = `<b>Error:</b> This discount code has reached its usage limit.`
          return;
        }
      }

      if (vm.form.customer.email) {
        // Once per customer
        if (price_rule.once_per_customer) {
            var orders = await getShopifyOrders(`${vm.form.customer.email} ${discount_code.code}`);
            // console.log("once per customer orders:",orders);
            if (orders.data.length > 0) {
              validCart = false;
              vm.discountStatus = `<b>Error:</b> Sorry, this discount can only be applied once per customer.`
              return;
            }
        }

        // Check if customer is in saved search group
        if (price_rule.prerequisite_saved_search_ids.length > 0) {

          for (var i in price_rule.prerequisite_saved_search_ids) {

            var search_id = price_rule.prerequisite_saved_search_ids[i];
            var savedSearch = await vm.getShopifySavedSearch(search_id);

            if (savedSearch.indexOf(vm.form.customer.email) === -1) {
              validCart = false;
              vm.discountStatus = `<b>Error:</b> This discount is not valid for this customer.`
              return;
            }
          }
        }
        // Check if customer is in list of valid customer ids
        if (price_rule.prerequisite_customer_ids.length > 0) {
          if (price_rule.prerequisite_customer_ids.indexOf(vm.form.shopifyCustomer.id) === -1) {
            validCart = false;
            vm.discountStatus = `<b>Error:</b> This discount is not valid for this customer.`
            return;
          }
        }
      
        // Minimum purchase price
        if (price_rule.prerequisite_subtotal_range) { 
          if (vm.checkout.items_subtotal_price/100 < Number(price_rule.prerequisite_subtotal_range.greater_than_or_equal_to)) {
            validCart = false;
            vm.discountStatus = `<b>Error:</b> Order must be more than ${vm.formatPrice(price_rule.prerequisite_subtotal_range.greater_than_or_equal_to)}!`;
            return;
          }
        }

        // Minimum quantity
        if (price_rule.prerequisite_quantity_range) { 
          if (vm.checkout.items.length < Number(price_rule.prerequisite_quantity_range.greater_than_or_equal_to)) {
            validCart = false;
            vm.discountStatus = `<b>Error:</b> Must order at least ${vm.formatPrice(price_rule.prerequisite_quantity_range.greater_than_or_equal_to)} items!`;
            return;
          }
        }

        // Check if discount applies to a specific country
        if (price_rule.entitled_country_ids.length > 0) {
          var shopifyCountries = await vm.getShopifyCountries();
          var formCountry = shopifyCountries.filter(x => x.name === vm.form.customer.shipping_address.country)[0];
          
          if (price_rule.entitled_country_ids.indexOf(formCountry.id) === -1) {
            validCart = false;
            vm.discountStatus = `<b>Error:</b> Sorry, this discount is not valid for your selected country.`
            return;
          }
        }

        /* ================== */
        /* APPLY DISCOUNTS */
        /* ================== */
        // Target Type: Line Item
        if (price_rule.target_type === 'line_item') {
          vm.checkout.items = vm.checkout.items.map((x) => {

            var discountAmount = 0;
            var lineDiscountStatus = [];

            if (price_rule.value_type === 'percentage') { discountAmount = (x.original_line_price/100)*Number(price_rule.value) }
            if (price_rule.value_type === 'fixed_amount') { discountAmount = Number(price_rule.value) }

            // Adjust the total discounts
            vm.checkout.discountAmount -= discountAmount;
            vm.checkout.discountAmount -= (x.line_level_total_discount/100);

            // Set the new line price
            if (price_rule.target_selection === 'all') {
              
              x.line_price = (x.original_line_price + discountAmount).toFixed(2);
              x.final_line_price = x.line_price; 

            } else if (price_rule.target_selection === 'entitled') {

              // Check if the variant id is in the list of entitlements
              if (price_rule.entitled_variant_ids.indexOf(x.variant_id) > -1) {

                // Check prerequisites
                // Minimum purchase price
                if (price_rule.prerequisite_subtotal_range) { 
                  if (x.final_line_price < Number(price_rule.prerequisite_subtotal_range.greater_than_or_equal_to)) {
                    validCart = false;
                    lineDiscountStatus.push(`<b>Error:</b> Must order at least ${vm.formatPrice(price_rule.prerequisite_subtotal_range.greater_than_or_equal_to)} worth of ${x.product_title}.`);
                  }
                }
                // Minimum quantity
                if (price_rule.prerequisite_quantity_range) { 
                  if (x.quantity < Number(price_rule.prerequisite_quantity_range.greater_than_or_equal_to)) {
                    validCart = false;
                    lineDiscountStatus.push(`<b>Error:</b> Must order at least ${price_rule.prerequisite_quantity_range.greater_than_or_equal_to} units of ${x.product_title}.`);
                  }
                }

                if (validCart) {
                  // Set the new line price
                  x.line_price = (x.original_line_price + discountAmount).toFixed(2);
                  x.final_line_price = x.line_price;  
                } else {
                  vm.discountStatus = lineDiscountStatus.join("<br>");
                  return;
                }

                // console.log("discountStatus:",vm.discountStatus);
                // console.log("validCart:",validCart);

              }
            }
            
            // console.log("discountAmount:",discountAmount)

            return x;

          })

          await vm.saveCheckout();
          vm.discountStatus = 'applied';
          // console.log("vm.checkout:",vm.checkout);
          return;

        }

        // Target Type: Free Shipping
        else if (price_rule.target_type === 'shipping_line') {
          if (vm.hasShippingRates !== 'Error') {
            // console.log("apply free shipping");

            vm.form.shippingOptions.map(x => { x.price = 0; return x; });
            // vm.checkout.discountAmount -= (vm.form.shippingOption.original_price*1);

            // console.log("vm.form:", vm.form);
            // console.log("applied discount vm.checkout:", vm.checkout);

            await vm.saveCheckout();
            vm.discountStatus = 'applied';

            return;
          } else {
            validCart = false;
            vm.discountStatus = '<b>Error:</b> please select a valid shipping method.'
          }
        }

        else {
          validCart = false;
          vm.discountStatus = '<b>Error:</b> not a valid discount.'
          return;
        }

      } else {

         validCart = false;
         vm.discountStatus = `<b>Error:</b> please provide an email address.`
         return;

       }


    },
    removeDiscount: async () => {

      vm.discountStatus = 'processing';
      vm.checkout.discountAmount = 0;

      vm.checkout.discount = {};
      vm.checkout.discountAmount = 0;
      vm.checkout.items = vm.checkout.items.map(x => { 
        x.line_price = x.original_line_price - (x.total_discount/100); 
        x.final_line_price = x.line_price;
        vm.checkout.discountAmount += (x.line_level_total_discount/100);
        return x; 
      });
      vm.form.shippingOptions.map(x => { x.price = x.original_price; return x; })

      await vm.saveCheckout();
      vm.discountStatus = 'open';

      // console.log("removed discount from vm.checkout:",vm.checkout);

    },

    /* Cart Method */
    getCheckout: async () => {

      var cartData = vm.cart;

      // Need to create a route to handle the cart that takes a JSON posting
      if (vm.$route.params.id && vm.cart === undefined) {
          cartData = await firebase.getCheckout(vm.$route.params.id);
      }

      // Format the cart for rendering as two decimal places
      cartData.items.forEach((item) => {
        // console.log("item:",item);
        for (const [key, value] of Object.entries(item)) {

          if (key.indexOf('price') > -1 & String(value).indexOf(".") === -1) {
            item[key] = value/100;
          }
        }
      })

      // Organize the checkout data
      vm.checkoutId = await firebase.getCheckoutId(vm.$route.params.id);
      vm.checkout = cartData;
      vm.checkout.id = vm.checkoutId;

      if (!vm.checkout.discount) { vm.checkout.discount = {} }

      // console.log("getCheckout CartData:",cartData);
    if (!vm.checkout.currency) { vm.checkout.currency = "GBP"; }

      await vm.setSavedData(vm.checkoutId, cartData);

    },
    cartTotal: () => {
      var total_price = vm.checkout.original_total_price/100;

      if (vm.form.shippingOption.price) { total_price += Number(vm.form.shippingOption.price); }
      if (vm.checkout.discountAmount > 0) { total_price -= vm.checkout.discountAmount; }

      return total_price.toFixed(2);
    },
    saveCheckout: () => {

      firebase.saveCheckout(vm.checkout, vm.checkoutId);

    },

    /* Sale */
    confirmPurchase: async () => {

      // Get payment method
      vm.checkoutStatus = 'processing';
      vm.selectedPaymentMethod = await vm.requestPaymentMethod(vm.dropinUI);

      if (vm.selectedPaymentMethod) {

        // Submit payment to Braintree
        vm.checkout.final_price = vm.cartTotal();
        vm.payment = await vm.submitPayment(vm.checkout.final_price, vm.selectedPaymentMethod);
        vm.payment.paymentMethod = vm.selectedPaymentMethod;

        if (vm.payment.status === 'submitted_for_settlement') {

          // // If successful, create shopify order
          vm.order = await vm.createShopifyOrder();

          // If the order is a subscription, create the recharge subscription
          // vm.order.subscription = await createRechargeSubscription({ order: vm.order });

          // Save order to checkout!
          if (vm.order.name) {
            
            vm.order.checkoutId = vm.checkoutId;

            await firebase.saveOrderToCheckout(vm.checkoutId, vm.order);
            await firebase.saveOrder(vm.order, String(vm.order.id));

            vm.checkoutStatus = 'complete';

            // Send user to order details
            vm.$router.push({ name: 'Order', params: { id: vm.order.id, order: vm.order, checkout: vm.checkout } });

          }

        } else {

            vm.checkout.error = "Error: "+vm.payment.message;
            vm.checkoutStatus = 'error';

        }

      } else {
        console.log({"Error": "Must provide valid payment method"});
      }

    },
    submitPayment: async (amount, paymentMethod) => {
      // console.log("Submit Payment:",amount);
      // console.log("Submit Payment Method:",paymentMethod);

      var transaction = {
        amount: amount,
        merchantAccountId: vm.merchantAccountId,
        options: {
          submitForSettlement: true
        }
      }

      // If we're using a saved payment method
      if (paymentMethod.token) { 
        transaction.customerId = vm.braintreeCustomer.id;
        transaction.paymentMethodToken = paymentMethod.token; 
      }
      // If we're using a newly generated payment method
      if (paymentMethod.nonce) { 
        transaction.paymentMethodNonce = paymentMethod.nonce; 
      }

      console.log("Submitting transaction:",transaction);

      return braintree({
        action: 'submitPayment',
        sandbox: isSandbox,
        transaction: transaction
      }).then((response) => {
        // console.log("submit payment response:",response.data);
        return response.data;
      }).catch((err) => {
        // console.log({ "error submitting payment": err })
        return err;
      });

    },
    createShopifyOrder: async () => {

      var orderData = {
        checkout: vm.checkout,
        customer: vm.form.customer,
        shippingOption: vm.form.shippingOption,
        payment: vm.payment
      };

      // Check if order is a subscription
      if (vm.checkout.items.findIndex(x => x.is_subscription === true) > -1) {
        orderData.tags = ["International","Subscription","Subscription First Order"];
      }

      console.log("[createShopifyOrder] orderData:",orderData.checkout.items);

      // Create the Order
      return createShopifyOrder(orderData)
      .then((response) => {
        // console.log("shopify order:",response);
        return response.data;
      }).catch((err) => {
        // console.log("error creating shopify order:",err);
        return err;
      });

    },
    getShopifyCheckout: async (checkout) => {

      return await getShopifyCheckout(checkout)
      .then((response) => {
        // console.log("retrieved shopify checkout:",response.data);
        return response.data.data.node;
      }).catch((err) => {
          // console.log("error retrieving shopify checkout:",err)
          return err;
      });

    },
    createShopifyCheckout: async (orderData) => {

      /* Create shopify checkout */
      // console.log("create shopify checkout")

      var newCheckout = await createShopifyCheckout(orderData)
      .then((response) => {
          return response.data;  
      }).catch((err) => {
          // console.log("error creating shopify checkout:",err)
          return err;
      });

      // console.log("new checkout:",JSON.stringify(newCheckout.checkoutCreate));

      if (newCheckout.checkoutCreate.checkoutUserErrors.length > 0) {
        return { "Errors": newCheckout.checkoutCreate.checkoutUserErrors }
      } else {
        return await vm.getShopifyCheckout(newCheckout.checkoutCreate);
      }

    },
    sendKlaviyoEvent: (event, email, data) => {
          
        console.log("=== Send Klaviyo Event ===");

        var body = {
            "token": "RpLQJY",
            "event": event,
            "customer_properties": {
                "$email": email
            }
        }

        if (data) { body.properties = data; }

        console.log("Klaviyo body:",body);

        var encoded_data = btoa(JSON.stringify(body));

        return axios({
            method: 'GET',
            url: 'https://a.klaviyo.com/api/track?data='+encoded_data,
        }).then(function(response) {
            console.log({ "Successfully sent event to Klaviyo": response.data })
        }).catch(function(err) {
            console.log({ "Error updating Klaviyo": err })
        })

    },
    trackInitiateCheckout: function() {

      var existingCheckout = JSON.parse(localStorage.getItem('initiateCheckout'));
      var currentTime = new Date().getTime();
      var maxTimestamp = currentTime - 3 * 60 * 60 * 1000;

      // console.log("maxTimestamp:",maxTimestamp);
      // console.log("existingCheckout:",existingCheckout);

      if (existingCheckout && existingCheckout.timestamp > maxTimestamp) {
        
        console.log("existingCheckout:",existingCheckout);
        console.log("currentTime",currentTime);

      } else {

        var trackingData = {
          "ecommerce": {
              "currencyCode": vm.checkout.currency,
              "checkout": {
                "products": vm.checkout.items.map(function(item) {
                  return {
                    "variant_id": item.variant_id,
                    "name": item.title,
                    "category": "Health & Wellness",
                    "price": item.price,
                    "quantity": item.quantity
                  }
                })
              }
          },
          "cart_total": (vm.checkout.total_price/100)
        }
        
        analytics.track('dl_begin_checkout',trackingData)

        trackingData.timestamp = new Date().getTime();

        console.log("trackingData:",trackingData);

        localStorage.setItem('initiateCheckout',JSON.stringify(trackingData))

      }

    }
  },
  watch: {
    'form.customer.shipping_address': {
      handler: (value) => {
        // console.log("value:",value);
        if (vm.sameBillingAddress) {
          vm.form.customer.billing_address = value;
        }
      }
    },
    'form.shippingOption': {
      handler: (value) => {
        console.log("Shipping Options:",value)
      }
    },
    'form.detailsPage': {
      handler: (value) => {
        console.log("Details Page:",value);

        // Track init checkout - only trigger this once every 3hrs
        if (value === 'address') {

          console.log("track initiateCheckout:",vm.checkout);
          vm.trackInitiateCheckout();

        }
        
        if (value === 'shipping') { 
          vm.getShippingRates(); 
        }
      }
    },
    'form.customer.email': {
      handler: (value) => {
        if (vm.validateEmail(value) && vm.form.detailsPage === 'address' && vm.trackedEvents.indexOf('initiateCheckout') === -1) {

          console.log("Track Klaviyo Started Ora Checkout event:",value);

          // trackStartedCheckout(value);
          vm.sendKlaviyoEvent('Started Ora Checkout', value, {
            "id": vm.checkout.id,
            "token": vm.checkout.token,
            "total_price": vm.checkout.total_price/100,
            "$value": vm.checkout.total_price/100,
            "total_discount": vm.checkout.total_discount/100,
            "line_items_subtotal": vm.checkout.items_subtotal_price/100,
            "currency": vm.checkout.currency,
            "line_items": vm.checkout.items.map(x => { return { 
              id: x.id, 
              price: x.price, 
              sku: x.sku, 
              variant_id: x.variant_id,
              product_id: x.product_id,
              product_title: x.product_title,
              image: x.featured_image,
              quantity: x.quantity,
              line_price: x.line_price,
              title: x.title,
              url: x.url
            }}),
            "url": "https://checkout.ora.organic/checkout/"+vm.checkout.id
          })

          vm.trackedEvents.push('initiateCheckout');

        }
      }
    },
    'sameBillingAddress': {
      handler: (value) => {
        // console.log("sameBillingAddress:",value);
        if (value === "true") {
          vm.tempBillingAddress = vm.form.customer.billing_address;
          vm.form.customer.billing_address = vm.form.customer.shipping_address;
        } else {
          vm.form.customer.billing_address = vm.tempBillingAddress;
        }

        vm.setSavedData('form')
        // console.log("billing_address:",vm.form.customer.billing_address)
      }
    }
  }
};
</script>
