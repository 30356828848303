import Vue from 'vue'
import VueRouter from 'vue-router'

// Views
import Home from '../views/Home.vue'

// Components
import Checkout from '../components/Checkout.vue'
import Order from '../components/Order.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/checkout/:id',
    name: 'Checkout',
    component: Checkout,
    props: true
  },
  {
    path: '/order/:id',
    name: 'Order',
    component: Order,
    props: true
  }
]

const router = new VueRouter({
  routes,
  mode: 'history'
})

export default router