<template>
    <div class="address">

        <div class="form-row flex">
          <div v-bind:class="[ 'input-group', { hasData: address.first_name }, 'flex-1' ]">
            <label>First name</label>
            <input type="text" name="first_name" v-model="address.first_name" class="full-width" placeholder="First name"/>
          </div>
          <div v-bind:class="[ 'input-group', { hasData: address.last_name }, 'flex-1', 'margin-right-0' ]">
            <label>Last name</label>
            <input type="text" name="last_name" v-model="address.last_name" class="full-width" placeholder="Last name"/>
          </div>
        </div>
        <div v-bind:class="[ 'input-group', { hasData: address.company }, 'full-width' ]">
          <label>Company (optional)</label>
          <input type="text" name="company" v-model="address.company" class="full-width" placeholder="Company"/>
        </div>
        
        <div v-bind:class="[ 'input-group', { hasData: address.address1 }, 'full-width' ]">
          <label>Address</label>
          <input type="text" name="address1" v-model="address.address1" class="full-width" placeholder="Address" style="position: absolute; z-index: -1"/>
          <vue-google-autocomplete
              id="map"
              classname="full-width hasData"
              placeholder="Address"
              v-on:placechanged="getAddressData"
              v-on:keypress="$emit('addressUpdated',address)"
              v-bind:value="address.address1"
              v-bind:key="addressKey"
              v-bind:country="['gb']"
              autocomplete="off"
          >
          </vue-google-autocomplete>
          
        </div>
        <div v-bind:class="[ 'input-group', { hasData: address.address2 }, 'full-width' ]">
          <label>Apartment, suite, etc. (optional)</label>
          <input type="text" name="address2" v-model="address.address2" class="full-width" placeholder="Apartment, suite, etc. (optional)"/>
        </div>
        <div v-bind:class="[ 'input-group', { hasData: address.city }, 'full-width' ]">
          <label>City</label>
          <input type="text" name="city" v-model="address.city" class="full-width" placeholder="City"/>
        </div>
        <div class="form-row flex">
          <div v-bind:class="[ 'input-group', { hasData: address.country }, 'flex-1', 'select' ]">
            <label>Country</label>
            <select name="country" v-model="address.country" class="full-width disabled" placeholder="Country">
              <option selected="selected">United Kingdom</option>
            </select>
          </div>
          <div v-if="showProvince()" v-bind:class="[ 'input-group', { hasData: address.province }, 'flex-1' ]">
            <label>State/Province</label>
            <input type="text" name="province" v-model="address.province" class="full-width" placeholder="State/Province"/>
          </div>
          <div v-bind:class="[ 'input-group', { hasData: address.zip }, 'flex-1', 'margin-right-0' ]">
            <label>Postal Code/Zip</label>
            <input type="text" name="zip" v-model="address.zip" class="full-width" placeholder="Postal Code/Zip" @keyup="$emit('addressUpdated',address)" @change="$emit('addressUpdated',address)" :class="{ error: !validateZip(address) }"/>
          </div>
        </div>
        <div v-bind:class="[ 'input-group', { hasData: address.phone }, 'full-width' ]">
          <label>Phone (Required)</label>
          <input type="text" name="phone" v-model="address.phone" class="full-width" placeholder="Phone (optional)"/>
        </div>

    </div>
</template>

<script>

import VueGoogleAutocomplete from 'vue-google-autocomplete';

export default {
  name: 'Address',
  props: {
    label: String,
    address: Object
  },
  components: {
    VueGoogleAutocomplete
  },
  data: () => {
    return {
      addressKey: 0,
      countriesWithProvinces: [ 
        "United States",
        "Canada",
        "Australia" 
      ]
    }
  },
  created: function () {
    // console.log("address:",this.address);
    this.address.country = "United Kingdom";

  },
  methods: {
    getAddressData: function (addressData, placeResultData, id) {
      console.log("addressData:",addressData);
      console.log("placeResultData:",placeResultData);
      console.log("id:",id);
      
      this.address.city = addressData.locality || placeResultData.address_components[2].long_name;
      this.address.country = addressData.country;
      this.address.province = addressData.administrative_area_level_1;
      this.address.address1 = `${addressData.street_number} ${addressData.route}`;

      if (addressData.postal_code) {
        this.address.zip = addressData.postal_code;
      }

      this.addressKey += 1;

      this.$emit("addressUpdated",this.address);

    },
    showProvince: function() {
      return this.countriesWithProvinces.indexOf(this.address.country) > -1;
    },
    validateZip: (address) => {
      if (!address.zip) {
        return true;
      } else if (address.country === "United Kingdom") {
        // console.log("[validateZip] address:",address);
        const re = /([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})/;
        return re.test(address.zip);
      } else {
        return true;
      }
    },
  },
  watch: {
    'address': {
      handler: function(value) {
        this.$emit("addressUpdated",value);
      },
      deep: true
    }
  }
};
</script>